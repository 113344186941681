'use strict'

Object.defineProperty(exports, '__esModule', {
    value: true,
})
exports.default = void 0
var traitInputAttr = {
    placeholder: 'z.B. Text hie',
}
// eslint-disable-next-line no-unused-vars
var _default = (exports.default = {
    assetManager: {
        addButton: 'Bild hinzuefüege',
        inputPlh: 'http://path/to/the/image.jpg',
        modalTitle: 'Bild uswähle',
        uploadTitle: 'Datei hie ablege oder zum Ufelade aaklicke',
    },
    // Here just as a reference, GrapesJS core doesn't contain any block,
    // so this should be omitted from other local files
    blockManager: {
        labels: {
            // 'block-id': 'Block Label',
        },
        categories: {
            // 'category-id': 'Kategorie Label',
        },
    },
    domComponents: {
        names: {
            '': 'Box',
            wrapper: 'Body',
            text: 'Text',
            comment: 'Kommentar',
            image: 'Biud',
            video: 'Video',
            label: 'Label',
            link: 'Link',
            map: 'Charte',
            tfoot: 'Tabellefuess',
            tbody: 'Tabellekörper',
            thead: 'Tabellechopf',
            table: 'Tabelle',
            row: 'Tabellezeile',
            cell: 'Tabellezelle',
        },
    },
    deviceManager: {
        device: 'Grät',
        devices: {
            desktop: 'Desktop',
            tablet: 'Tablet',
            mobileLandscape: 'Mobil Landschaft',
            mobilePortrait: 'Mobil Portrait',
        },
    },
    panels: {
        buttons: {
            titles: {
                preview: 'Vorschau',
                fullscreen: 'Vollbild',
                'sw-visibility': 'Komponänte azeige',
                'export-template': 'Code azeige',
                'open-sm': 'Style Manager öffne',
                'open-tm': 'Ischtelligä',
                'open-layers': 'Ebene öffne',
                'open-blocks': 'Blöck öffne',
            },
        },
    },
    selectorManager: {
        label: 'Klasse',
        selected: 'Usgwählt',
        emptyState: '- Status -',
        states: {
            hover: 'Hover',
            active: 'Click',
            'nth-of-type(2n)': 'Even/Odd',
        },
    },
    styleManager: {
        empty: 'Wähle Si es Element us, bevor Si dä Style Manager verwändet',
        layer: 'Ebene',
        fileButton: 'Bilder',
        sectors: {
            general: 'Allgemein',
            layout: 'Layout',
            typography: 'Typographie',
            decorations: 'Dekoratione',
            extra: 'Extra',
            flex: 'Flex',
            dimension: 'Grösse',
        },
        // The core library generates the name by their `property` name
        properties: {
            float: 'Usrichtig',
            display: 'Aazeig',
            position: 'Position',
            top: 'Obe',
            right: 'Rächts',
            left: 'Links',
            bottom: 'Unne',
            width: 'Breiti',
            height: 'Höchi',
            'max-width': 'Breiti max.',
            'max-height': 'Höchi max.',
            margin: 'Üssere Abstand',
            'margin-top': 'Üssere Abstand obe',
            'margin-right': 'Üssere Abstand rächts',
            'margin-left': 'Üssere Abstand links',
            'margin-bottom': 'Üssere Abstand unne',
            'margin-top-sub': 'Obe',
            'margin-right-sub': 'Rächts',
            'margin-bottom-sub': 'Unne',
            'margin-left-sub': 'Links',
            'padding-top-sub': 'Obe',
            'padding-right-sub': 'Rächts',
            'padding-bottom-sub': 'Unne',
            'padding-left-sub': 'Links',
            'border-width-sub': 'Breiti',
            'border-style-sub': 'Stil',
            'border-color-sub': 'Farb',
            'border-top-left-radius-sub': 'Obe links',
            'border-top-right-radius-sub': 'Obe rächts',
            'border-bottom-right-radius-sub': 'Unne rächts',
            'border-bottom-left-radius-sub': 'Unne links',
            padding: 'Innere Abstand',
            'padding-top': 'Innere Abstand obe',
            'padding-left': 'Innere Abstand links',
            'padding-right': 'Innere Abstand rächts',
            'padding-bottom': 'Innere Abstand unne',
            'font-family': 'Schriftart',
            'font-size': 'Schriftgrössi',
            'font-weight': 'Schriftstärchi',
            'letter-spacing': 'Zeicheabstand',
            color: 'Schriftfarb',
            'line-height': 'Zilehöchi',
            'text-align': 'Textusrichtig',
            'text-shadow': 'Textschatte',
            'text-shadow-h': 'X',
            'text-shadow-v': 'Y',
            'text-shadow-blur': 'Unschärfi',
            'text-shadow-color': 'Farb',
            'border-top-left': 'Rand obe links',
            'border-top-right': 'Rand obe rächts',
            'border-bottom-left': 'Rand unne links',
            'border-bottom-right': 'Rand unne rächts',
            'border-radius-top-left': 'Rand Radius obe links',
            'border-radius-top-right': 'Rand Radius obe rächts',
            'border-radius-bottom-left': 'Rand Radius unne links',
            'border-radius-bottom-right': 'Rand Radius unne rächts',
            'border-radius': 'Rand Radius',
            border: 'Rand',
            'border-width': 'Randbreiti',
            'border-style': 'Randstil',
            'border-color': 'Randfarb',
            'box-shadow': 'Boxschatte',
            'box-shadow-h': 'X',
            'box-shadow-v': 'Y',
            'box-shadow-blur': 'Unschärfi',
            'box-shadow-spread': 'Verteilig',
            'box-shadow-color': 'Farb',
            'box-shadow-type': 'Typ',
            background: 'Hintergrund',
            'background-image': 'Hintergrundbild',
            'background-repeat': 'Hintergrund widerhole',
            'background-position': 'Hintergrundposition',
            'background-attachment': 'Hintergrundahang',
            'background-size': 'Hintergrössi',
            'background-color': 'Hintergrundfarb',
            'background-image-sub': 'Biud',
            'background-repeat-sub': 'Widerholig',
            'background-position-sub': 'Position',
            'background-attachment-sub': 'Aahang',
            'background-size-sub': 'Grössi',
            transition: 'Übergang',
            'transition-property': 'Übergang: Typ',
            'transition-duration': 'Übergang: Duur',
            'transition-timing-function': 'Übergang: Zytfunktion',
            'transition-property-sub': 'Eigeschaft',
            'transition-duration-sub': 'Duur',
            'transition-timing-function-sub': 'Zyt',
            perspective: 'Perspektive',
            transform: 'Transformation',
            'transform-rotate-x': 'Rotation X',
            'transform-rotate-y': 'Rotation Y',
            'transform-rotate-z': 'Rotation Z',
            'transform-scale-x': 'Skalierig X',
            'transform-scale-y': 'Skalierig Y',
            'transform-scale-z': 'Skalierig Z',
            'flex-direction': 'Flex Usrichtig',
            'flex-wrap': 'Flex Umbruch',
            'justify-content': 'Vertikali Usrichtig',
            'align-items': 'Senkrechte Usrichtig',
            'align-content': 'Achsenusrichtig',
            order: 'Reihefolg',
            'flex-basis': 'Flex Basis',
            'flex-grow': 'Flex Wachse',
            'flex-shrink': 'Flex Schrumpfe',
            'align-self': 'Eigeni Usrichtig',
        },
    },
    traitManager: {
        empty: 'Wähle Si es Elemänt us bevor Si dr Eigeschaftsmanager verwände',
        label: 'Komponänte Eigeschafte',
        traits: {
            // The core library generates the name by their `name` property
            labels: {
                id: 'ID',
                alt: 'Alternativtext',
                title: 'Titel',
                href: 'Link',
            },
            // In a simple trait, like text input, these are used on input attributes
            attributes: {
                id: traitInputAttr,
                alt: traitInputAttr,
                title: traitInputAttr,
                href: {
                    placeholder: 'z.B. https://google.com',
                },
            },
            // In a trait like select, these are used to translate option names
            options: {
                target: {
                    false: 'Das Fänschter',
                    _blank: 'Nöis Fänschter',
                },
            },
        },
    },
    storageManager: {
        recover: 'Möchtet Sie ungspichereti Änderige widerhärstelle?',
    },
})
